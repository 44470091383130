<template>
  <div class="">
    <div class="sidebar">
      <v-navigation-drawer
        style="background-color: #ffffff"
        v-model="drawer"
        :mini-variant="miniVariant"
        :mini-variant-width="50"
        :app="isApp"
        :fixed="isAbsolute"
        color=""
      >
        <v-list dense class="pa-0">
          <span class="hidden-sm-and-down" style="text-align: center">
            <a v-if="isAccountLogo" class="main_logo">
              <h1 class="mb-0 mt-2">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fcadt_logo.png?alt=media&token=69b87955-7940-424c-9a5e-f83dfc1cd59e"
                  height="60"
                  alt="Cadt Logo"
                />
              </h1>
            </a>
            <a v-if="!isAccountLogo" class="">
              <h1 class="mb-0">
                <img
                  class="small_img fade-in"
                  src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fcadt_logo.png?alt=media&token=69b87955-7940-424c-9a5e-f83dfc1cd59e"
                  height="25"
                  alt="Cadt Logo"
                />
              </h1>
            </a>

            <v-divider
              class="ma-2 mt-0"
              color="white"
              v-bind:class="{ devide_with: !miniVariant }"
            />
          </span>

          <div class="block_menu">
            <template v-for="item in items">
              <v-row v-if="item.heading" :key="item.heading" align="center">
                <v-col cols="6">
                  <v-subheader v-if="item.heading">{{
                    item.heading
                  }}</v-subheader>
                </v-col>
              </v-row>
              <v-list-group
                v-else-if="item.children"
                :key="item.id"
                v-model="item.url_ref"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name_en }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-group>
              <v-list-item
                v-else
                router
                :id="'id' + item.id"
                :to="item.url_ref ? lang + item.url_ref : ''"
                :class="item.class + ' ' + item.active_class"
                :style="{ 'background-color': backHoverColor }"
                :key="item.id"
                @click.native="clickMe(item)"
              >
                <v-icon v-text="item.icon" style="width: 20px"></v-icon>
                <v-list-item-content style="width: 20px" class="pl-6">
                  <v-list-item-title>{{ $t(item.name_en) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </div>
        </v-list>
      </v-navigation-drawer>
    </div>

    <v-app-bar
      app
      color="backgroundWhite"
      v-bind:class="{ sidebar_small: miniVariant }"
    >
      <div class="hidden-lg-and-up">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-spacer />
      </div>

      <v-spacer />

      <div class="ml-2 hidden-sm-and-down text-h4">
        {{ $t("school_management_system") }}
      </div>

      <v-spacer />

      <div class="hidden-sm-and-down header_icon"></div>
      <v-list class="pb-0">
        <v-list-item>
          <v-list-item-content
            style="padding: 0px; display: inline; min-height: 10px"
          >
            <v-list-item-title class="text-h9"
              >{{ this.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ this.role }} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-badge
              bordered
              bottom
              color="green accent-4"
              dot
              offset-x="10"
              offset-y="10"
            >
              <v-avatar size="36px" v-if="!pic">
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fuser.png?alt=media&token=7260a3c7-948d-4b98-826e-107d748944df"
                  alt="User"
              /></v-avatar>
              <v-avatar size="36px" v-if="pic">
                <v-img :src="pic" alt="User"
              /></v-avatar>
            </v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            link
            style="min-height: 35px"
            @change="switchLocale('kh')"
          >
            <v-img
              src="/images/flags/kh.svg"
              class="mr-2"
              width="19"
              height="14"
            />
            <v-list-item-title>ភាសាខ្មែរ</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            style="min-height: 35px"
            @change="switchLocale('en')"
          >
            <v-img
              src="/images/flags/us.svg"
              class="mr-2"
              width="19"
              height="14"
            />
            <v-list-item-title>English</v-list-item-title>
          </v-list-item>
          <v-list-item link style="min-height: 35px" @click="signOut">
            <v-icon class="mr-1">mdi-logout</v-icon>
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { Trans } from "@/plugins/Translation";
import { i18n } from "../i18n";
import store from "@/store";
export default {
  data: () => ({
    drawer: null,
    miniVariant: false,
    handOver: false,
    isApp: true,
    role: "",
    name: "",
    isAbsolute: false,
    isAccountLogo: true,
    backHoverColor: "",
    pic: "",
    items: [],
  }),
  props: {
    source: String,
  },
  methods: {
    clickMe() {
      // eslint-disable-next-line no-console
      this.items.forEach((element) => {
        element.active_class = "";
      });
      //store the new active li id
    },

    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: { locale } });
        return Trans.changeLocale(locale).then(() => {
          this.$router.push(to.location);
        });
      }
    },
    signOut() {
      this.$store.dispatch("logOut");
      this.$router.go({
        name: "SignIn",
      });
    },

    loadPermission() {
      let loggedUser = store.getters.getLoggedUser;
      this.items = loggedUser.payload.permissions;
      this.pic = loggedUser.payload.user.photoURL;
      this.role = loggedUser.payload.roles[0].name_en;
      this.name = loggedUser.payload.user.name_en;
    },
    hideSidebar() {
      this.miniVariant = !this.miniVariant;
      if (this.miniVariant) {
        this.isAbsolute = true;
        this.isAccountLogo = false;
        this.isApp = false;
      } else {
        this.isAbsolute = false;
        this.isAccountLogo = true;
        this.isApp = true;
      }
    },

    setActiveSideNav() {
      let pathURL = window.location.pathname;
      //eslint-disable-next-line no-console
      if (pathURL == "/") {
        pathURL = "/en";
      }
      this.items.forEach((element) => {
        element.active_class = "";
        let path = element.path;
        if (path == "/en") {
          path = "";
        }
        if ("/" + i18n.locale + path == pathURL) {
          element.activeClass = "active";
        }
      });
    },
  },
  components: {},
  computed: {
    supportedLocales() {
      return Trans.supportedLocales;
    },
    currentRouteName() {
      return this.$route.name;
    },
    lang() {
      return "/" + i18n.locale;
    },
  },
  mounted() {
    this.setActiveSideNav();
    this.loadPermission();
  },
};
</script>
<style scoped>
/* .theme--light.v-input{
		background-color: transparent !important;
	} */
.v-application a {
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
}
.v-application .link_buttom {
  margin-bottom: 10px;
}
h1.ml-2 a {
  text-decoration: none;
  color: #333;
}
.v-text-field.hidden-sm-and-down .v-input__control {
  background-color: #fff;
}
.v-app-bar--is-scrolled {
  -webkit-box-shadow: 0px 8px 15px -10px rgba(150, 150, 150, 0.25) !important;
  -moz-box-shadow: 0px 8px 15px -10px rgba(150, 150, 150, 0.25) !important;
  box-shadow: 0px 8px 15px -10px rgba(150, 150, 150, 0.25) !important;
}
.theme--light.v-text-field--solo-inverted .v-input__control .v-input__slot {
  background-color: #fff !important;
}
.line_right {
  border-right: 1px solid #ccc;
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #ffffff;
  border-bottom: 1px solid #fff;
}
.v-toolbar {
  box-shadow: none;
}

.main_logo {
  width: 100%;
  display: inline-block;
}
.main_logo .v-responsive.v-image {
  text-align: center;
  width: 100%;
  padding-left: 0;
}
.menu_right {
  float: right;
}

.block_menu .v-list-item__content > *,
.block_menu1 .v-list-item__content > * {
  color: #000000;
  font-size: 14px !important;
  font-weight: 490 !important;
  line-height: 44px !important;
  text-transform: capitalize;
  font-family: "Poppins", serif !important;
}
.v-list .v-list-item__icon i {
  color: #54667a;
}
/* .v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 10px 10 5 10px !important;
} */
.v-list .v-list-item--active .v-list-item__title,
.v-list .v-list-item--active i,
.v-list .v-list-item--active .v-list-item__action i {
  color: #3316f2;
}

.v-list .v-list-item--active .v-list-item__title,
.v-list .v-list-item--active i,
.theme--light.v-app-bar.v-toolbar.v-sheet {
  border-bottom: none !important;
}
/* .header_i con .v-btn{ height: 35px !important; width: 35px !important; } */
.menuable__content__active[data-v-61dd7a3d] {
  top: 50px !important;
}

.v-list .v-list-item--active {
  background-color: #d6d0fc;
  border-radius: 30px;
}
.sidebar_small {
  margin-left: 50px !important;
}
.angle_action {
  right: 0;
  z-index: 10;
  position: absolute;
  top: 50px;
}
.angle_action_small {
  right: 12px;
  z-index: 10;
  position: absolute;
  top: 52px;
}
.sidebar:hover .angle_action_small {
  right: 0px;
}
.angle_action .arr_icon {
  font-size: 16px;
  color: #dad5d5;
}
.sidebar:hover .angle_action .arr_icon {
  color: #4c9aff;
}
.sidebar:hover .angle_action_small .arr_icon {
  color: #4c9aff;
}
.small_img {
  margin: 9px 0;
  transition: 0.6s ease-in;
}
.hide_item_samll {
  display: none;
}
.sidebar:hover .hide_item_samll {
  display: block;
}
.devide_with {
  width: 90%;
}

.v-list-item--dense,
.v-list--dense .v-list-item.v-divider {
  min-height: 0 !important;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

@media (max-width: 576px) {
  .backgroundWhite >>> .v-toolbar__content {
    padding-left: 0;
  }
  .custom_search_phone {
    border: #d6d6d6 1px solid !important;
    border-radius: 9px;
  }
}
</style>
